import { Link,useNavigate } from 'react-router-dom';
import Header from './header';
import { useState,useEffect } from 'react';
import Vtu from './Assets/call.png';
import Electricity from './Assets/Electricity.png';
import Cable from './Assets/tv.png';
import Data from './Assets/Data.png';
function Dashboard() {
  const [name, SetName]=useState('');
  const [balance, setBalance]=useState('');
  const [email, setEmail]=useState('');
  const [Isloggedin, setIsLogged] = useState(false);

  const navigate = useNavigate();

  const retrieveUserSession = async () => {
    try {
      const session = await localStorage.getItem("user_session");
      if (session !== null) {
        const session_items = JSON.parse(session);
        setEmail(session_items.email);
        SetName(session_items.name);
        setBalance(session_items.balance);
//      
        setIsLogged(true);
      } else {
        setIsLogged(false);
      }
    } catch (error) {
      console.error("Error retrieving user session:", error);
    }
  };


  //const logincheck = () => {
    // if(Isloggedin=="false") {
     // navigate('/Home');
    //}
  //}

  useEffect(() => {
  //logincheck();
retrieveUserSession();
  }, []);
 

  return (

    <div style={{display:"flex",flexDirection:"column",width:"100%",height:'3700px',color:'white',background: 'linear-gradient(to right,purple,#ffffff,purple)',marginTop:'-20px', }}>

    <Header/>
    <div style={{marginTop:'90px',textAlign:"center",color:"grey",}}>


<p></p>
<p>Welcome {name} </p>

<p>Your Wallet balance is &#8358;{balance}</p>
<div style={{display:"flex",flexDirection:"row",}}>
<p style={{marginLeft:"76px"}}><button style={{color:"gold"}}><Link to="/Fund" style={{textDecoration:"none",textAlign:"center"}}> Fund Wallet</Link></button></p>
<p style={{marginLeft:"76px"}}><button style={{backgroundColor:"white",color:""}}><Link to="/Fund" style={{textDecoration:"none"}}>Reseller upgrade</Link></button></p>
</div>

<div style={{color:"grey",marginLeft:"20px",}}  >
<p><img src={Vtu} style={{width:"20px",height:"20px"}}/> <Link to="/Buyvtu" style={{ textDecoration: 'none',color:"grey",}}>Buy Vtu</Link></p>
<p><img src={Data} style={{width:"20px",height:"20px"}}/> <Link to="/Buydata"  style={{ textDecoration: 'none',color:"grey",}}> Buy Data </Link></p>
<p><img src={Cable} style={{width:"20px",height:"20px"}}/> <Link to="/Buycable"  style={{ textDecoration: 'none',color:"grey",}}>  Buy cabletv </Link> </p>
<p> <img src={Electricity}  style={{width:"20px",height:"20px"}}/><Link to="/Buyelectricity"  style={{color:"grey", textDecoration: 'none',}}>Buy Electricity</Link></p>
<p> <Link to="/TransactionHistory"  style={{ textDecoration: 'none',color:"grey",}}>TransactionHistory</Link></p>
</div>
</div>

</div>
  );
}

export default Dashboard;