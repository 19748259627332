import React, { useState, useEffect } from 'react';
import './fade.css';
import image1 from './Assets/1.jpeg';

const FadeComponent2 = ({ src }) => {
  const [fade, setFade] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(prev => !prev);
    }, 4000); // Change every 2 seconds
    return () => clearInterval(interval);
  }, []);

  return (
    <img
      src={image1}
      className={`fade-image ${fade ? 'fade-in' : 'fade-out'}`}
      alt="Fading"
      style={{width:'270px',height:"280px"}}    />
  );
};

export default FadeComponent2;