import React, { useState } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import { Link, useNavigate} from 'react-router-dom';
//import Header from '../Header';
import Dashboard from './Dashboard';
import { Badge } from 'react-bootstrap';

import Logo from './Assets/logo.png';

import Header from './header';
//import BigButton from './Bigbutton';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const[name, SetName]=useState();
  const[balance,setBalance]=useState();
  const [Usertype,setUsertype]=useState('');
  const navigate = useNavigate();


const [loggingIn,SetLogging ]=useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    SetLogging(true);
    fetch('https://www.haykaytelecoms.com.ng/api/Api_h', {
      method: 'POST',
      headers: {
       // 'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email, password }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status =="Success") {
          //localStorage.Set()
          localStorage.setItem("user_session",
          JSON.stringify({
            email: data.email,
            name: data.name,
     balance: data.balance,
     Usertype:data.usertype,
                //loggedin:true,      
    
    }));
          if (Usertype=="user") { navigate("/Dashboard");} 
          if (Usertype=="reseller"){
navigate("/resellerDashboard")
          }
          if (Usertype=="api"){ navigate("/ApiDashboard");} 
          else {
          navigate("/Dashboard");
           
          //setError('Wrong Details');
          }}
      })
      .catch((error) => {
        console.error('Error logging in:', error);
        // Handle errors
      });
  };



  

  return (



 <div style={{display:"flex",flexDirection:"column",width:"100%",height:'3700px',color:'white',background: 'linear-gradient(to right,purple,#ffffff,purple)',marginTop:'-20px', }}>

    <Header/>
      {error && <Alert variant="danger">{error}</Alert>}
      
      {!loggingIn?
        <div style={{marginTop:'390px',textAlign:"center",fontSize:"38px"}}>
 <Form onSubmit={handleSubmit}>
        <Form.Group controlId="email" style={{marginBottom:'30px'}}>
          <Form.Label>Email:</Form.Label>
          <Form.Control
            type="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="password">
          <Form.Label>Password:</Form.Label>
          <Form.Control
            type="password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </Form.Group>

        <Button variant="primary" type="submit" style={{marginLeft:'90px',marginTop:'40px',marginBottom:'30px'}}>
          Login
        </Button>
   <p> <Link to="/register" style={{ textDecoration: 'none',color:'white',}}>Dont have an Account?.. Register!</Link></p> 
   <p> <Link to="/Forgotpassword" style={{ textDecoration: 'none',color:'white',}}>Forgot Password?</Link></p> 
   
      </Form>
    
    </div>
    
    :<div style={{
      display: 'flex',
      flexDirection:'column',
      justifyContent: 'center',
      alignItems: 'center',
    
     }}>   
     
   <div> <img src={Logo} style={{marginBlockStart:"120px", width: '160px', height: '150px' }} /></div>
        <p style={{alignItems:'center',color:'pink'}}>Loging In,Please Wait!</p>

      </div>
      
        
        }
    </div>

  );
}

export default Login;
