import React, { useEffect,useState } from 'react';
import Header from './header';

import { useNavigate } from 'react-router-dom';
//i
import Logo from "./Assets/logo.png";
//function  Cable;




function Electricity() {
  const[loading,setloading] =useState(false);
  const [discotype, setDiscotype] = useState(12);
  const [metertype, setMetertype] = useState('prepaid');
  const [meternumber, setMeternumber] = useState('');
  const [amount, setAmount] = useState('');
  const [phonenumber, setPhonenumber] = useState('');
  const [errors, setErrors] = useState([]);
  const [Phonenumber,Set_phonenmuber]=useState('');
  const [disco_type,set_discotype]=useState('');
  const [meter_number,set_meter_number]=useState('');
  const [meter_type,set_meter_type]=useState('');
  const[loggedin,setloggedin]=useState('');
   const [email,setemail]=useState('');
  const[Loading,setLoading]=useState('false');


  const handleSubmit = (event) => {
    event.preventDefault();
    // Add your form submission logic here
  };


  async function retrieveUserSession() {
    try {   
const session=await localStorage.getItem("user_session");
  if (session!==undefined) {
        const session_items=JSON.parse(session);
    setemail(session_items.email);

  
        }
        else{alert('You are not Log In')}
    } catch (error) {
        
    }
  }

  const Buy =async () => {
    setLoading('true')
    //const state = await NetInfo.fetch();
        
        
    
      fetch('https://haykaytelecoms.com.ng/api/buy_electricity', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        meter_number:meter_number,
        amount:amount,
        Phonenumber:Phonenumber,
        disco_type:disco_type,
        email:email,})
    }) .then((response) => response.json())
        .then((response) => {
        setemail(response.email);
        if (response.status=="Success"){
          alert('Transaction Successful');
         // navigation.navigate('dashboard')
        }
         else{
          setLoading(false);
          alert(response.mssg);
         // alert('Failed Transaction');
        }
        
        });
    
      }
    


  useEffect(() => {
    retrieveUserSession();
   });


 
  return(
       <div style={{color:"white",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
  marginInlineStart:"700",
  backgroundColor: "purple",marginTop:'-20px',
}}><Header/>



                       
      {!loading?   <div
               style={{marginLeft:"30px", }}
            >


<div style={{marginTop:"30px",}}>
              <p className="ml-12 mt-10"></p>
              <p className="ml-12 mt-10"></p>
              <p className="ml-12 mt-10">..</p>
          
    </div>


    <div style={{marginTop:"30px",}}>
              <p className="ml-12 mt-10"></p>
              <p className="ml-12 mt-10"></p>
              <p className="ml-12 mt-10">..</p>
          
    </div>

  
 <form onSubmit={Buy} >
             <p style={{color:"white",
  justifyContent: "center",
  alignItems: "center",}}><label className="ml-12">Select Distributor</label>
              <select
                name="discotype"
                value={discotype}
                onChange={(event) => setDiscotype(event.target.value)}
              >
                <option value="12">Eko Eletric</option>
                <option value="11">Abuja Electric</option>
                <option value="15">Kano Electric</option>
                <option value="Enugu">Enugu Electric</option>
                <option value="18">Portharcout Electric</option>
                <option value="10">Ikeja Electric</option>
                <option value="14">Ibadan Electric</option>
                <option value="15">Kaduna Electric</option>
                <option value="17">Jos Electric</option>
                <option value="Benin">Benin Electric</option>
              </select>
              </p>
              <p style={{color:"white",
  justifyContent: "center",
  alignItems: "center",}}>
     <label>Select MeterType </label>
               
              <select
                name="Metertype"
                value={metertype}
                onChange={(event) => setMetertype(event.target.value)}
              >
                <option value="prepaid">Prepaid</option>
                <option value="postpaid">Postpaid</option>
              </select>
              </p >
              <p
              style={{color:"white",
              justifyContent: "center",
              alignItems: "center",}}>
                <label>Meter number</label>
                <input
                  type="text"
                  name="meternumber"
                  placeholder="Enter meter number"
                  value={meternumber}
                  onChange={(event) => setMeternumber(event.target.value)}
                />
              </p>
              <p style={{color:"white",
  justifyContent: "center",
  alignItems: "center",}}>
                <label>Amount</label>
                <input
                  type="text"
                  name="amount"
                  placeholder="amount"
                  value={amount}
                  onChange={(event) => setAmount(event.target.value)}
                />
              </p>
              <p style={{color:"white",
  justifyContent: "center",
  alignItems: "center",}}>
                <label>Customer phonenumber</label>
                <input
                  type="text"
                  name="Phonenumber"
                  placeholder="Enter phonenumber"
                  value={phonenumber}
                  onChange={(event) => setPhonenumber(event.target.value)}
                />
              </p>
              <p className="ml-12">
                <input type="submit" name="submit" value="submit" />
              </p>
            </form>

    </div>


    :<div style={{
      display: 'flex',
      flexDirection:'column',
      justifyContent: 'center',
      alignItems: 'center',
    
     }}>   
     
   <div> <img src={Logo} style={{ width: '160px', height: '150px' }} /></div>
        <p style={{alignItems:'center',color:'pink'}}>Loading,Exercise Pattience!</p>

      </div>
      
        
}

    </div>
  );
}

export default Electricity;

//export default cab;