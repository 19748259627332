import React, { useState } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import { Link, useNavigate} from 'react-router-dom';
//import Header from '../Header';
import Dashboard from './Dashboard';
import { Badge } from 'react-bootstrap';

import Logo from './Assets/logo.png';

import Header from './header';
//import BigButton from './Bigbutton';

function Register() {
  
  
  const navigate = useNavigate();
  const[Registering, SetRegistering]=useState(false);
  const[name, SetName]=useState('');
  const[balance,setBalance]=useState('');
const [firstName, setFirstname]=useState();
const[lastName,setLastname]=useState('');
const[Phonenumber,setPhonenumber]=useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const[confirmPassword, setcPassword]=useState('');
  const [error, setError] = useState(null);
  const [Referalcode,setReferalcode]=useState('');
  
  const [Usertype,setUsertype]=useState('');
  

//Registering
//const [loggingIn,SetLogging ]=useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    //setRegistering
    SetRegistering(true);
    fetch('https://www.haykaytelecoms.com.ng/api/register', {
      method: 'POST',
      headers: {
       // 'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({  email,name, password,Phonenumber,Referalcode, }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status =="Success") {
          
          alert('Registeration Successful');
          //localStorage.Set()
          localStorage.setItem("user_session",
          JSON.stringify({
            email: data.email,
            name: data.name,
     balance: data.balance,
     Usertype:data.usertype,
                //loggedin:true,      
    
    }));
          if (Usertype=="user") { navigate("/Dashboard");} 
          if (Usertype=="reseller"){
navigate("/resellerDashboard")
          }
          if (Usertype=="api"){ navigate("/ApiDashboard");} 
          else {
          navigate("/Dashboard");
           
          //setError('Wrong Details');
          }}
      })
      .catch((error) => {
        console.error('Error logging in:', error);
        // Handle errors
      });
  };



  

  return (



 <div style={{display:"flex",flexDirection:"column",width:"100%",height:'3700px',color:'white',background: 'linear-gradient(to right,purple,#ffffff,purple)',marginTop:'-20px', }}>

    <Header/>
      {error && <Alert variant="danger">{error}</Alert>}
      
      {!Registering?
        <div style={{marginTop:'390px',textAlign:"center",fontSize:"38px"}}>
 
 <Form onSubmit={handleSubmit}>
            <p style={{marginBottom:'20px'}}><Form.Group controlId="firstName">
             <Form.Label> Enter Name:</Form.Label>
               <Form.Control
                type="text"
                name="firstName"
                value={firstName}
                onChange={(e) =>  SetName(e.target.value)}
                required
              />
            </Form.Group>
            </p>
           
    <p style={{marginBottom:'20px'}}>    <Form.Group controlId="email">
              <Form.Label>Email:</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={email}
                
                onChange={(e) => setEmail(e.target.value)}
       required
              />
            </Form.Group>
    </p>
    <p style={{marginBottom:'20px'}}>    <Form.Group controlId="password">
              <Form.Label>Password:</Form.Label>
              <Form.Control
                type="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
       
                required
              />
            </Form.Group>
    </p>
    <p style={{marginBottom:'20px'}}>    <Form.Group controlId="confirmPassword">
              <Form.Label>Confirm Password:</Form.Label>
              <Form.Control
                type="password"
                name="confirmPassword"
                value={confirmPassword}
                onChange={(e) => setcPassword(e.target.value)}
       
                required
              />
            </Form.Group>
    </p>
    
    <div style={{marginBottom:'20px',marginTop:"20px"}}>    <Form.Group controlId="Phonenumber">
              <Form.Label>Phone Number:</Form.Label>
              <Form.Control
                type="text"
                name="Phonenumber"
                value={Phonenumber}
       
                onChange={(e) => setPhonenumber (e.target.value)}
                
              />
            </Form.Group>
            </div>
            <div style={{marginBottom:'20px',marginTop:"20px"}}>    <Form.Group controlId="Referalcode">
              <Form.Label>Referal Code(optional)</Form.Label>
              <Form.Control
                type="text"
                name="Referalcode"
                value={Referalcode}
              
                onChange={(e) => setReferalcode(e.target.value)}
              />
            </Form.Group>
    </div>
            <Button variant="primary" type="submit">
              Register
            </Button>
          </Form>
            
    
    </div>
    
    :<div style={{
      display: 'flex',
      flexDirection:'column',
      justifyContent: 'center',
      alignItems: 'center',
    
     }}>   
     
   <div> <img src={Logo} style={{marginBlockStart:"120px", width: '160px', height: '150px' }} /></div>
        <p style={{alignItems:'center',color:'pink'}}>Processing,Please Wait!</p>

      </div>
      
        
        }
    </div>

  );
}

export default Register;
