import Picture from './Assets/1.png';
import Logo from './Assets/logo.png';
import Mock from './Assets/Mock1.png';
import { useEffect,useState } from 'react';
import {useNavigate,Link } from "react-router-dom";
import Paystack from  './Assets/paystack.png';
import Paystack2 from  './Assets/paystack2.jpg';
import FadeComponent from './fadecomponent';
import FadeComponent2 from './fadecomponent2';
import intro from './Assets/Intro1.png';
import './App.css';
import Login from './Login';
import Register  from './Register';
import header from './header';
import Amazon from './Assets/Amazon.png';
import Header from './header';
import Stack from 'react-bootstrap/Stack';
import Dstv from './Assets/DStv.jpg';
import Image2 from './Assets/Data.png';
//./fadecomponent

function App() {

  const [currentImage, setCurrentImage] = useState(1);
  const [fadeIn, setFadeIn] = useState(true);
  const [src, setsorce]=useState([Dstv,Image2]);

  const handleFadeOut = () => {
    setFadeIn(false);
    setTimeout(() => {
      setCurrentImage(currentImage === 1 ? 2 : 1);
      setFadeIn(true);
    }, 1000); // adjust the timeout to match your fade duration
  };


  return (
   
   
 <div style={{display:"flex",flexDirection:"column",width:"100%",height:'3700px',color:'white',background: 'linear-gradient(to right,purple,#ffffff,purple)',marginTop:'-20px', }}>

  <Header/>
  <Stack direction="horizontal" gap={3} style={{marginTop:"84px",marginBottom:"60px"}}>
  <p className="p-2" style={{float:"right"}}> <button ><Link to="/Docs" style={{textDecoration:"none"}}>Developers Docs</Link></button> </p>
 <p className="p-2" style={{float:"right"}}> <button ><Link to="/Register" style={{textDecoration:"none"}}>Register</Link></button> </p>
 <p className="p-2" style={{float:"right"}}><button ><Link to="/Login" style={{textDecoration:"none"}}>Login</Link></button></p>
 
 </Stack>
  
  <div style={{marginTop:"15px",marginLeft:"80px"}} >
  <h1 style={{color:'gold'}}>Welcome to Haykay</h1>
  <p style={{color:'gold'}}>Nigeria's Number 1 Bill Payment App!</p>
</div>

<div style={{float:"right", marginLeft:'58px',marginRight:"auto",direction:"flex",flexDirection:"row",}} >

<FadeComponent2 src={Picture} />

<FadeComponent src={intro} />

</div>
   <div style={{marginLeft:"40px"}}>
   <p style={{color:'pink'}}>Enjoy the best deals on Data, VTU,</p>
   
  <p style={{color:'pink'}}> And Bills Payment Services.</p>
<p>  <h1 class="typewriter-text">We are reliable!.</h1></p>
</div>
     <div style={{color:"pink",textAlign:"center",textDecoration:"none"}}>                           <p>Buy your vtu,data,cable subscription at cheap and affordable prices </p>
                                 <p> How do i get started? </p>
<p ><a href="Register" style={{textDecoration:"none",}}>Sign Up,</a> FREE</p>
<p class="ml-8">Fund your wallet, Your wallet is credited immediately!</p>
<p class="ml-8">Start selling or buying data,vtu, subscribe to Dstv,Gotv etcs</p>
<p><a href="/becomereseller" style={{textDecoration:"none",color:'pink'}}>Wants to become a reseller(Agent) and get data at cheaper price?</a></p>
</div>                 <div style={{flexDirection:'column'}} >
<div style={{marginBottom:'240px',flexDirection:"column"}}>  

<img alt='' src={Mock} style={{ margin:'auto',
  display:'block', justifySelf:'center', width:'320px'}} />
<p style={{textAlign:"center"}}><a href="https://haykaytelecoms.com.ng/haykaynew.apk"  download="Haykay">Download Our Android App </a></p>

<img alt='' src={Amazon} style={{justifySelf:'center',margin:'auto',width:'390px',paddingLeft:'40px',display:'flex'}}/>

</div>


<div style={{marginTop:'-150px'}}>  
                <p style={{color:'#cfffe5',fontSize:'20px',alignItems:'center',textAlign:'center'}}>&#x2714; Safepayment Method</p>
                <img alt='' src={Paystack2} style={{width:'170px',height:'190px',margin:'auto',justifySelf:'center',display:'flex'}}/>
                  </div>

                  </div>
<div style={{ textAlign:'center', marginLeft:"23px"}}>
           <p style={{color:'#cfffe5',}}>Haykay</p>
                                Haykay offers the best in  Airtime(VTU),Data subscriptions,BILLS PAYMENT  (GOTV,DSTV &STARTIMES,IBEDC,IKEDC,AEDC ETC)
                                <p>For Enquires/More Info: call 07015564961</p> 
                            <p >Email Address</p> 

                           <p> enquiries@hakay.com.ng </p>
                           <p>customercare@haykay.com.ng	</p> 
   <p>Office Address </p>
                           <p>First Floor Leventis Building 	</p>
                   <p>Dugbe,Ibadan 	</p>
  </div>
    </div>
  );
}

export default App;
