import Logo from "./Assets/logo.png";
//import Header from './Header';
import './headerstyle.css';
//./headerstyle.css
export default function Header() {
  return (
   
<div style={{width:"100%",
position:"fixed",marginTop:"-20px"}}>


<p style={{display:"flex",flexDirection:"row",backgroundColor:"purple",color:"white",}}><h1>Hakay</h1> <img alt='' src={Logo} style={{ width: '70px', height: '70px',align:"right",
marginLeft:"70%",}}/></p>

    </div>
  );
}

 