import React, { useEffect,useState } from 'react';
import Header from './header';
//i
import Logo from "./Assets/logo.png";
function Vtu() {
  const [errors, setErrors] = useState([]);



  const [planId, setplan_id]=useState('');
  const [networkId, setNetworkId]=useState(''); 
  const [Phonenumber,Set_phonenmuber]=useState('');
  const [email, setemail]=useState('');
  const[Balance,setbalance] =useState('');
  const[loading,setloading] =useState(false);
  const[loggedin,setloggedin]=useState('');
   

async function retrieveUserSession() {
    try {   
const session=await localStorage.getItem("user_session");
  if (session!==undefined) {
        const session_items=JSON.parse(session);
    setemail(session_items.email);

    setbalance(session_items.balance);
  
        }
        else{alert('You are not Log In')}
    } catch (error) {
        
    }
  }
  
  const Buydata = async () => {

    setloading(true);

    //const state = await NetInfo.fetch();
     fetch('https://www.haykaytelecoms.com.ng/api/buy_vtu', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },

      body: JSON.stringify({
           email: email,
        //plan_id:planId,
        network_id:networkId,
        phonenumber: Phonenumber,
    
      })
 }).then((response) => response.json())
    .then((response) => {
      setloading(false);
  
      if (response.status!=="Success"){
        alert(response.mssg);  
      
    }
     else{
     // Alert.alert('Succesful Transactions')
      alert(response.mssg)}
   
     
    });
    
  }

  //return(

 useEffect(() => {
    retrieveUserSession();
  }, []);
 
  return(
       <div style={{color:"white",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
  marginInlineStart:"700",
  backgroundColor: "purple",marginTop:'-20px',
}}><Header/>


<div style={{marginTop:"30px",}}>
              <p className="ml-12 mt-10"></p>
              <p className="ml-12 mt-10"></p>
              <p className="ml-12 mt-10">..</p>
          
    </div>
    <div style={{marginTop:"30px",}}>
              <p className="ml-12 mt-10"></p>
              <p className="ml-12 mt-10"></p>
              <p className="ml-12 mt-10">..</p>
          
    </div>

                       
      {!loading?   <div
               style={{marginLeft:"30px", }}
            >



            <form
              onSubmit={Buydata}
              className="ml-12"
               >
              <label
                style={{
                  marginTop:"20px" ,
               
                }}
              >
              Select Network
              </label>
              <p>
                <select
                  name="network_id"
                  id="network_id"
                  value={networkId}
                  onChange={(event) => setNetworkId(event.target.value)}
                >
                  <option value="1">Mtn</option>
                  <option value="3">Glo</option>
                  <option value="2">Airtel</option>
                  <option value="4">9mobile</option>
                </select>
              </p>
               <p>
               <input
                style={{ color: 'purple' }}
                type="tel"
                name="phonenumber"
                id="phonenumber"
                required
                placeholder="Enter the receiving phone number e.g 08435..."
                value={Phonenumber}
                onChange={(event) => Set_phonenmuber(event.target.value)}

/>    </p>
          
              <input type="submit" value="Submit" name="Submit" />
            </form>
         
      <p className="ml-12 text-rose-500" style={{ color: 'purple' }}>
    HAYKAY
      </p>
    </div>


    :<div style={{
      display: 'flex',
      flexDirection:'column',
      justifyContent: 'center',
      alignItems: 'center',
    
     }}>   
     
   <div> <img src={Logo} style={{ width: '160px', height: '150px' }} /></div>
        <p style={{alignItems:'center',color:'pink'}}>Loading,Exercise Pattience!</p>

      </div>
      
        
}

    </div>
  );
}

export default Vtu;