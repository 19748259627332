import React, { useEffect,useState } from 'react';
import Header from './header';
//i
import Logo from "./Assets/logo.png";
function MyComponent() {
  const [errors, setErrors] = useState([]);



  const [planId, setplan_id]=useState('');
  const [networkId, setNetworkId]=useState(''); 
  const [Phonenumber,Set_phonenmuber]=useState('');
  const [email, setemail]=useState('');
  const[Balance,setbalance] =useState('');
  const[loading,setloading] =useState(false);
  const[loggedin,setloggedin]=useState('');
   

async function retrieveUserSession() {
    try {   
const session=await localStorage.getItem("user_session");
  if (session!==undefined) {
        const session_items=JSON.parse(session);
    setemail(session_items.email);

    setbalance(session_items.balance);
  
        }
        else{alert('You are not Log In')}
    } catch (error) {
        
    }
  }
  
  const Buydata = async () => {
   alert(planId)
  //alert(networkId)
  
    setloading(true);

    //const state = await NetInfo.fetch();
     fetch('https://www.haykaytelecoms.com.ng/api/buy_data', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },

      body: JSON.stringify({
           email: email,
        plan_id:planId,
        network_id:networkId,
        phonenumber: Phonenumber,
    
      })
 }).then((response) => response.json())
    .then((response) => {
      setloading(false);
  
      if (response.status!=="Success"){
        alert(response.mssg);  
      
    }
     else{
     // Alert.alert('Succesful Transactions')
      alert(response.mssg)}
    });
    
  }

  //return(

 useEffect(() => {
    retrieveUserSession();
  }, []);
 
  return(
       <div style={{color:"white",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
  marginInlineStart:"700",
  backgroundColor: "purple",marginTop:'-20px',
}}><Header/>



            {errors.length > 0 && (
              <div className="alert alert-danger text-red-600 ml-12px">
                <ul>
                  {errors.map((error) => (
                    <li key={error}>{error}</li>
                  ))}
                </ul>
              </div>
            )}
                       
      {!loading?   <div
               style={{marginLeft:"30px" }}
            >
              <p className="ml-12 mt-10">Codes to check data balance</p>
              <p>MTN SME *461*4#</p>
              <p>MTN Gifting *131*4# or *460*260#</p>
            

            <form
              onSubmit={Buydata}
              className="ml-12"
               >
              <label
                style={{
                  marginTop: 20,
                  marginLeft: 20,
                }}
                className="@error('network_id') {{$message}} @enderror"
              >
                NETWORK
              </label>
              <p>
                <select
                onChange={(event) => setNetworkId(event.target.value)}
                >
                  <option value="1">Mtn</option>
                  <option value="3">Glo</option>
                  <option value="2">Airtel</option>
                  <option value="4">9mobile</option>
                </select>
              </p>
              <p>
                <label
                  style={{
                    color: 'white',
                    marginTop: 20,
                    marginLeft: 20,
                  }}
                  className="@error('plan_id') {{$message}} @enderror"
                >
                  SELECT DATATYPE
                </label>
              </p>
              <p>
             <select
                name="planId"
                id="planId"
                value={planId}
                onChange={(event) => setplan_id(event.target.value)}
              >

<option value="1">MTN 500.00mb SME gifting #140 30days </option>
<option value="2"> MTN SME 1GB #265 </option>
<option value="3" >MTN SME 2GB #330  </option>
<option value="4" >MTN  SME 3GB #595 </option>
<option value="5" > MTN  SME 5GB #952 </option>
<option value="6"> MTN  SME 10GB #1600</option>
<option value="22" > GLO 500MB #155  </option>
<option value="23" > GLO 1GB #285  </option>
<option value="24" > GLO 2GB #500  </option>
<option value="25" > GLO 3GB #785  </option>
<option value="26" > GLO 5GB  #960 </option>
<option value="27" > GLO 10GB #2630  </option>
<option value="28" > GLO 3.95GB GIFTING #3520  </option>
<option value="42" > GLO 18.25GB GIFTING #4400  </option>
<option value="43" > GLO 29.5GB GIFTING #6980  </option>
<option value="44" > GLO 50GB #8709  </option>

<option value="28" > AIRTEL SME 500MB #170  </option>
<option value="29">  AIRTEL SME 1GB #260  </option>
<option value="30">  AIRTEL SME 2GB #500  </option>
<option value="31">  AIRTEL SME 5GB #1300  </option>

<option value="34"> 9MOBILE SME 500MB #150 1 MONTH </option>
<option value="36" >  9MOBILE SME 1GB #320 1 MONTH </option>
<option value="37" >  9MOBILE SME 3.0GB #380 1 MONTH  </option>
<option value="39" > 9MOBILE SME 10GB #1558  1 MONTH </option>



              </select>
              </p>
              <p>
               <input
                style={{ color: 'purple' }}
                type="tel"
                name="phonenumber"
                id="phonenumber"
                required
                placeholder="Enter the receiving phone number e.g 08435..."
                value={Phonenumber}
                onChange={(event) => Set_phonenmuber(event.target.value)}

/>    </p>
          
              <input type="submit" value="Submit" name="Submit" />
            </form>
         
      <p className="ml-12 text-rose-500" style={{ color: 'purple' }}>
    HAYKAY
      </p>
    </div>


    :<div style={{
      display: 'flex',
      flexDirection:'column',
      justifyContent: 'center',
      alignItems: 'center',
    
     }}>   
     
   <div> <img src={Logo} style={{ width: '160px', height: '150px' }} /></div>
        <p style={{alignItems:'center',color:'pink'}}>Loading,Exercise Pattience!</p>

      </div>
      
        
}

    </div>
  );
}

export default MyComponent;